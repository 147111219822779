const getConfig = (hostname) => {
  const newRelicConfig = {
    local: {
      isEnabled: true,
      accountID: "3296629",
      agentID: "538604562",
      licenseKey: "NRJS-f17c797f0217acd5b71",
      applicationID: "538604562",
    },
    np: {
      isEnabled: true,
      accountID: "3296629",
      agentID: "538604413",
      licenseKey: "NRJS-f17c797f0217acd5b71",
      applicationID: "538604413",
    },
    pp: {
      isEnabled: true,
      accountID: "3296629",
      agentID: "538606858",
      licenseKey: "NRJS-f17c797f0217acd5b71",
      applicationID: "538606858",
    },
    prod: {
      isEnabled: true,
      accountID: "3296628",
      agentID: "538606859",
      licenseKey: "NRJS-74fc5a4903bc3aee5a9",
      applicationID: "538606859",
    },
  };

  const environments = {
    // Localhost
    localhost: "local",
    // Non-Prod url
    "luma-dev.clouddqtext.capitalone.com": "np",
    // Pre-Prod url
    "luma-qa.clouddqtext.capitalone.com": "pp",
    // Prod naked domain
    "luma.co.uk": "prod",
  };

  const environmentType = environments[hostname];
  const config = newRelicConfig[environmentType];
  return config;
};

const config = getConfig(window.location.hostname);

window.config = {
  appDynamics: {
    isEnabled: false,
  },
  newRelic: {
    isEnabled: config.isEnabled,
    accountID: config.accountID,
    agentID: config.agentID,
    licenseKey: config.licenseKey,
    applicationID: config.applicationID,
  },
};
